import React from "react";
function HeaderLower() {
  return (
    <>
      <div className="header_lower">
        Ashley and Elite Rewards are proud to support the American Cancer
        Society to make a life changing difference.
      </div>
    </>
  );
}
export default HeaderLower;
